.home {
  background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #cfdef3,
    #e0eafc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #cfdef3,
    #e0eafc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.user {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.header {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #2f4e71d9,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #2f4e71d9,
    #141e30c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.link {
  font-family: "SF Mono", monospace;
  color: rgb(0, 112, 243);
  padding-bottom: 3px;
  border-bottom: dotted;
}

.card {
  background-color: rgb(36, 41, 46);
  color: white !important;
}

.user-data {
  background: rgb(246, 248, 250); /* fallback for old browsers */
}

@media (max-width: 37.5em) {
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}

.info-item {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0px 1rem 0.5rem;
  white-space: nowrap;
}

.repo-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.repo-list ul li {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
  color: #586069;
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.2);
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

@media (max-width: 600em) {
  .repo-list ul li .repo {
    padding: 1.5rem;
  }
}

.repo-list ul li:hover {
  box-shadow: 0 8px 20px -15px rgba(0, 0, 0, 0.2);
}

.repo-list ul li:focus {
  box-shadow: 0 8px 20px -15px rgba(0, 0, 0, 0.2);
}

.repo-list ul li h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #24292e;
  margin-bottom: 0.75rem;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.repo-list ul li p {
  font-size: 14px;
  margin-bottom: 2rem;
}

.repo-list ul li .header {
  margin-bottom: 2rem;
}

.repo-list ul li .name {
  display: flex;
  align-items: center;
}

.repo-list ul li .name svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-list ul li .name h3 {
  margin: 0;
}

.repo-list ul li .stats {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #6a737d;
}

.repo-list ul li .stats .left {
  flex-grow: 1;
  display: flex;
}

.repo-list ul li .stats span {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-list ul li .stats svg {
  margin-right: 0.25rem;
}

.repo-list ul li .stats span .language {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
}

.dropdown {
  background: transparent;
  border: 1px solid rgb(126, 126, 126);
  border-radius: 8px;
  font-size: 13px;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.dropdown:hover .dropdown-menu {
  visibility: visible;
  z-index: 99999;
  opacity: 1;
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #6a737d;
}

.emoji {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease;
  margin-left: 4px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
